<template>
  <div>
    <container>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" :serviceType="2"></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

// 引入store模块
import produceOrderStoreModule from '@/store/modules/produce'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部组件
    IndexTable: () => import('@/components/produceOrder/IndexTable'),
    Detail: () => import('@/components/produceOrder/Detail')
  },
  created () {
    if (!this.$store.hasModule('produceOrder')) {
      this.$store.registerModule('produceOrder', produceOrderStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')

    this.setBottomComponent('IndexTable')
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('produceOrder')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
